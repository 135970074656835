import { LocalstorageService } from 'src/app/services/localstorage.service';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  apiUrl: string = environment.ApiConfig.student

  constructor(private http: HttpClient, private _localStorage: LocalstorageService) { }

  fetchPDFReport(langCode) {
    let uuid = sessionStorage.getItem('userSub')
    let env = environment.env;
    if (env == 'preprod') {
      env = 'test'
    }
    if (env == 'stage') {
      env = 'prod'
    }
    let token = this._localStorage.getCookie('token')
    if (!token) {
      token = ''
    }
    return this.http.get(`${this.apiUrl}/student/report?uuid=${uuid}&env=${env}&lang=${langCode}&entity_id=${environment.entity_id}&remove_proteen=${environment.remove_proteen}&remove_proteen=${environment.remove_proteen}&usersToken=${token}`)
  }

  // added fetchpdfreport function for short report 0112022
  fetchPDFShortReport(langCode) {
    let uuid = sessionStorage.getItem('userSub')
    let env = environment.env;
    let isShort = true;
    if (env == 'preprod') {
      env = 'test'
    }
    if (env == 'stage') {
      env = 'prod'
    }
    let token = this._localStorage.getCookie('token')
    if (!token) {
      token = ''
    }
    return this.http.get(`${this.apiUrl}/student/report?uuid=${uuid}&env=${env}&lang=${langCode}&entity_id=${environment.entity_id}&usersToken=${token}&isShort=${isShort}`);
  }



  downloadFile(url) {
    return this.http.get(url, { responseType: 'blob' });
  }
}
