<div class="container-fluid px-0" style="height:100%">
    <!-- commented on 06012023 -->
    <!-- <mat-toolbar style="height: 10%;">
        <div class="row w-100">
            <div class="col-sm-12 text-center webkitScrollDisable logo-container">
                <img src="./../../assets/images/partner/Placeholder_Logo.svg" href="#" alt="proteen-logo" class="logo cursor-pointer border-none partner-logo partner-mobile" style="width: 210px;height: 53px;">
                <img src="./../../assets/images/partner/proteen.png" href="#" alt="proteen-logo" class="logo cursor-pointer border-none proteen-logo" (click)="navigateToHome()">
            </div>
        </div>
    </mat-toolbar> -->
    <mat-toolbar style="height: 10%; padding-left: 36px;">
        <div class="row w-100">
            <div class="col-sm-12 text-center webkitScrollDisable logo-container">
                <img src="./../../assets/images/partner/KES_logo_wwb.png" href="#" alt="proteen-logo" class="logo cursor-pointer border-none partner-logo partner-mobile" style="width: 4rem; height: 3.5rem;">
                <img src="./../../assets/images/partner/proteen.png" href="#" alt="proteen-logo" class="logo cursor-pointer border-none proteen-logo" style="height: 55px;" (click)="navigateToHome()">
            </div>
        </div>
    </mat-toolbar>
    <div class="login-container signin-container" style="height: 90%;">
        <div class="row">
            <div class="col-md-7 vertical pr-0 m-hide">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide><img src="./assets/images/other/singup/New_Banner_1.png"></ng-template>
                    <ng-template carouselSlide><img src="./assets/images/other/singup/New_Banner_2.png"></ng-template>
                    <ng-template carouselSlide><img src="./assets/images/other/singup/New_Banner_3.png"></ng-template>
                    
                </owl-carousel-o>
    
            </div>
            <div class="col-md-5 sign-in-box">
                <div class="ScollBar">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
